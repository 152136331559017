<template>
    <div class="list">
        <table class="table" v-if="type === 1">
            <thead class="head">
                <tr>
                    <th>{{ $t('coin_treasure.currency') }}</th>
                    <th>{{ $t('coin_treasure.lump_sum') }}</th>
                    <th>{{ $t('coin_treasure.interest_accruing') }}</th>
                    <th>{{ $t('coin_treasure.estimated_annual') }}</th>
                    <th>{{ $t('coin_treasure.cumulative_income') }}</th>
                    <!-- <th>{{ $t('coin_treasure.level_reward') }}</th> -->
                    <th>{{ $t('futures.order_action') }}</th>
                </tr>
            </thead>
            <tbody class="body">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{ item.token }}</td>
                    <td>{{ item.amount + item.totalInterests + item.totalExtraInterests }}</td>
                    <td> {{ item.amount }}</td>
                    <td><span :class="item.interestRate > 0 ? 'up' : 'down'">{{ Math.round(item.interestRate * 10000) /
                        100
                    }}%</span></td>
                    <td> {{ item.totalInterests }}</td>
                    <!-- <td> {{ item.totalExtraInterests }}</td> -->
                    <!-- <td>
                        <button class="btn btn-primary" @click="redeemFunc(item)">{{$t('coin_treasure.ransom')}}</button>
                    </td> -->
                    <td v-if="item.status === 1"><button class="btn btn-primary" @click="redeemFunc(item)">{{ $t('coin_treasure.early_redemption') }}</button></td>
                    <td v-if="item.status === 2">{{ $t('coin_treasure.redemption') }}</td>
                    <td v-if="item.status === 3">{{ $t('coin_treasure.redeemed') }}</td>
                </tr>
            </tbody>
        </table>
        <table class="table" v-else>
            <thead class="head">
                <tr>
                    <th>{{ $t('coin_treasure.currency') }}</th>
                    <th>{{ $t('coin_treasure.subscription_date') }}</th>
                    <th>{{ $t('coin_treasure.lump_sum') }}</th>
                    <th>{{ $t('coin_treasure.estimated_annual') }}</th>
                    <th>{{ $t('coin_treasure.cumulative_income') }}</th>
                    <!-- <th>{{ $t('coin_treasure.level_reward') }}</th> -->
                    <th>{{ $t('coin_treasure.maturity_date') }}</th>
                    <th>{{ $t('coin_treasure.state') }}</th>
                    <th>{{ $t('futures.order_action') }}</th>
                </tr>
            </thead>
            <tbody class="body">
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>{{ item.token }}</td>
                    <td>{{ new Date(item.timeCreated).formatDate() }}</td>
                    <td>{{ item.amount + item.totalInterests + item.totalExtraInterests }}</td>
                    <td><span :class="item.interestRate > 0 ? 'up' : 'down'">{{ Math.round(item.interestRate * 10000)
                        / 100
                    }}%</span></td>
                    <td> {{ item.totalInterests }}</td>
                    <!-- <td> {{ item.totalExtraInterests }}</td> -->
                    <td> {{ new Date(item.timeCreated + (item.numDays * 60 * 60 * 24 * 1000)).formatDate() }}</td>
                    <td> {{ statusToText(item.status) }}</td>
                    <td v-if="item.status === 1"><button class="btn btn-primary" @click="redeemFunc(item)">{{ $t('coin_treasure.early_redemption') }}</button></td>
                    <td v-if="item.status === 2">{{ $t('coin_treasure.redemption') }}</td>
                    <td v-if="item.status === 3">{{ $t('coin_treasure.redeemed') }}</td>
                </tr>
            </tbody>
        </table>
        <div class="no-data-indicator m-5" v-if="tableData && tableData.length === 0">
            {{ $t('orders.no_orders') }}
        </div>
        <div v-if="isShow">
            <Dialog ref="earnModal" :data="data"></Dialog>
        </div>
    </div>
</template>

<script>

// import Coin from '../../Components/Coin.vue';
import Dialog from './RedeemDialog.vue';

export default {
    components: { Dialog },
    props: ['tableData', 'type'],
    data() {
        return {
            uid: null,
            amount: 0,
            data: {},
            isShow: false
        };
    },
    created() {
    },
    mounted() {

    },
    methods: {
        redeemFunc(data) {
            // this.uid = data.uid
            // this.amount= data.amount
            this.isShow = true
            this.data = data
            // 重新挂载组件
            this.$nextTick(() => {
                this.showDialog()
            })
        },
        hideModel() {
            // this.isShow = false
        },
        showDialog: function () {
            const modal = this.$refs.earnModal
            modal.showModal();
        },
        statusToText(status) {
            switch (status) {
                case 1:
                    return this.$t('coin_treasure.interest_accruing');
                case 2:
                    return this.$t('coin_treasure.redemption');
                case 3:
                    return this.$t('coin_treasure.redeemed');
            }
            return '';
        }
    },
};
</script>
