<template>
    <div class="w-100 prompted-symbol">
        <div class="d-flex">
            <token-icon :symbol="symbol" />
        </div>
        <div class="symbol-content">
            <div class="row">
                <div class="col">
                    <h2>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</h2>
                </div>
                <div class="col-auto">
                    <div class="price px-2">{{ '$'+symbol.price }}</div>
                </div>
            </div>
            <div :class="symbol.up ? 'up' : 'down'">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</div>
        </div>
    </div>
</template>

<script>
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['symbol'],

    methods: {
        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        }
    }
};
</script>

<style scoped>

.prompted-symbol .token-icon>* {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    background-size: cover;
    background-repeat: no-repeat;
}

.symbol-content {
    /* background: #fff;
    color: #161616; */
    border-radius: 0.5rem;
    padding-top: 1.5rem;
    line-height: 2;
}

.symbol-content h2 {
    font-size: 1.25rem;
}

.symbol-content .up {
    color: var(--up);
}

/* .symbol-content .slide-price {
    color: #acafbe;
} */
.symbol-content .up::before {
    content: url('@/assets/images/rising-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}

.symbol-content .down {
    color: var(--down);
}

.symbol-content .down::before {
    content: url('@/assets/images/falling-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}
</style>