<template>
    <footer class="footer-simple">
        <ul>
            <li>
                <router-link to="/">{{ $t('home.title') }}</router-link>
            </li>
            <template v-if="footer && footer.length > 0">
                <li v-for="item in footer[0].items" :key="item.id" :class="{'no-login-none':!showUs(item.id)}">
                    <router-link rel="noopener noreferrer nofollow" :to="'/post/' + item.id"><span v-if="showUs(item.id)">{{ item.title }}</span></router-link>
                </li>
            </template>
            <li>&copy; {{ new Date().getFullYear() }} {{ sysconfig.name }}</li>
        </ul>
    </footer>
</template>


<script>
import { getAppContext } from 'utilities/helper';
export default {
    data() {
        return { context: null };
    },

    computed: {
        footer: function () {
            return this.context.homeConfig ? this.context.homeConfig.footer : null;
        }
    },

    created() {
        this.context = getAppContext();
    },
    methods: {
        showUs(id){
            if(id != 2008 || (id == 2008 && this.context.profile)){
                return true
            } else {
                return false
            }
        }
    }
};
</script>