<template>
    <nav class="navbar">
        <div ref="symbolsSideNav" class="offcanvas offcanvas-start" tabindex="-1" id="symbolsNavbar">
            <div class="offcanvas-header">
                <ul class="nav nav-underline">
                    <li v-if="sysconfig.bOptionEnabled" class="nav-item">
                        <a :class="{ active: type === 1 }" @click="showSymbols(1)" class="nav-link" href="javascript:void(0)">{{ $t('general.boption') }}</a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: type === 2 }" @click="showSymbols(2)" class="nav-link" href="javascript:void(0)">{{ $t('general.futures') }}</a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ active: type === 3 }" @click="showSymbols(3)" class="nav-link" href="javascript:void(0)">{{ $t('general.exchange') }}</a>
                    </li>
                </ul>

                <!-- close -->
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <loading-indicator v-if="!symbols" />
                <template v-else>
                    <input type="text" v-model="searchName" @input="search">
                    <ul>
                        <li v-for="sym in symbolList" :key="sym.id">
                            <div class="d-flex align-items-center" v-if="sym.metadata.id === symbol.metadata.id">

                                <!-- symbol name -->
                                <b class="ms-2">{{ sym.metadata.name }}</b>
                            </div>
                            <a v-else @click="goto(sym)" href="javascript:void(0)" class="d-flex align-items-center">

                                <!-- symbol name -->
                                <b class="ms-2 flex-fill">{{ sym.metadata.name }}</b>

                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </template>
            </div>
        </div>
    </nav>
</template>
<style scoped>
.offcanvas {
    background-color: var(--bg-primary);
}
</style>
<script>
import { Offcanvas } from 'bootstrap';
import { getSymbolManagerAsync } from 'utilities/helper';

import '@/assets/svg/icon-arrow-right.svg';

export default {
    props: ['symbol'],

    data() {
        return {
            bsOffcanvas: null,
            type: -1,
            symbols: null,
            symbolList:null,
            searchName:''
        }
    },

    mounted() {
        this.type = this.symbol.metadata.type;
        this.bsOffcanvas = Offcanvas.getOrCreateInstance(this.$refs.symbolsSideNav);
    },

    methods: {
        show: function () {
            // display the sidenav
            this.symbols = null;
            this.symbolList = null;
            this.bsOffcanvas.show();

            // refresh symbol list
            this.showSymbols(this.type * 1);
        },

        showSymbols: function (type) {
            this.type = type;

            const self = this;
            getSymbolManagerAsync().then((mgr) => {
                const output = [];
                mgr.getSymbols(type).forEach(sym => {
                    output.push({
                        metadata: {
                            id: sym.id,
                            name: sym.name,
                            type: sym.type,
                            symbol: sym.symbol,
                            baseSymbol: sym.baseSymbol,
                            svgIcon: sym.svgIcon
                        }
                    });
                });
                self.symbols = output;
                self.symbolList = output;
            });
        },

        goto: function (sym) {
            // close the offset-canvas first
            const nav = Offcanvas.getInstance(this.$refs.symbolsSideNav);
            if (nav) {
                nav.hide();
            }

            const self = this;
            Vue.nextTick(() => {
                // then route to the trade page for the specified symbol.
                const type = sym.metadata.type;
                const route = (type === 1 ? '/boption/' : (type === 3 ? '/exchange/' : '/futures/')) + sym.metadata.symbol.toLowerCase();
                self.$router.push({ path: route });
            });
        },
        search(){
            this.symbolList = this.symbols.filter(item=>item.metadata.name.toUpperCase().includes(this.searchName.toUpperCase()));
        }
    }
}
</script>

<style scoped>
.nav-link {
    min-width: 5rem;
    text-align: center;
    color: #fff;
    font-weight: 600
}

.nav-link.active{
    color: #fff;
}

li {
    list-style-type: none;
}

.offcanvas-body li>a,
.offcanvas-body li>div {
    display: block;
    padding: 1rem 0;
    border-bottom: 1px solid var(--divider);
    color: #fff;
}

a>svg {
    height: 1rem;
    width: 1rem;
}
</style>