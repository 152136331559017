<template>
    <section class="page orders-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('home.header_futures_orders') }}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <ul class="page-top-nav">
                        <li>
                            <router-link class="nav-link" to="/futures/openorders">{{ $t('orders.open_orders') }}</router-link>
                        </li>
                        <li class="active">
                            <router-link class="nav-link active" to="/futures/orders">{{ $t('orders.all_orders') }}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="!orders" class="row">
                <div class="col">
                    <loading-indicator />
                </div>
            </div>
            <template v-else>
                <div v-if="orders.length === 0" class="row">
                    <div class="col">
                        <div class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="order-item" v-bind:key="order.orderId" v-for="order in orders">
                            <div class="row title-row">
                                <div class="col">
                                    <b>{{ order.symbolName }} </b>
                                    <span v-if="order.buyUp" class="color-up">{{ $t('futures.label_buy_up') }} <i class="fa fa-arrow-up"></i></span>
                                    <span v-else class="color-down">{{ $t('futures.label_buy_down') }} <i class="fa fa-arrow-down"></i></span>

                                    <span class="badge bg-danger" v-if="order.status === 1">{{ $t('orders.status_open') }}</span>
                                </div>
                            </div>

                            <div class="row content-row">
                                <div class="col-4 col-md-3">
                                    <label>{{ $t('futures.order_amount') }}</label>
                                    {{ order.numHands }} {{ $t('futures.label_unit') }}
                                </div>

                                <div class="col-4 col-md-3">
                                    <label>{{ $t('futures.order_margin_pct') }}</label> {{ 100 / order.leverage }}%
                                </div>
                                <div class="col-4 col-md-3">
                                    <label>{{ $t('futures.order_fee') }}</label> {{ order.totalFee }} <small class="text-muted">USDT</small>
                                </div>
                                <div class="col-4 col-md-3">
                                    <label>{{ $t('futures.order_create_price') }} </label>
                                    <span v-if="order.marketPrice === true">{{ $t('futures.label_market_price') }}</span>
                                    <span v-else>{{ order.createPrice }} USDT</span>
                                </div>
                                <div class="col-4 col-md-3">
                                    <label>{{ $t('futures.order_avg_price') }}</label>
                                    {{ order.matchPrice }}
                                </div>
                                <div class="col-4 col-md-3">
                                    <label>{{ $t('futures.order_stop_profit_point') }}</label>
                                    <span v-if="order.stopProfitPoint > 0">{{ order.stopProfitPoint }}</span>
                                    <span v-else class="text-muted">--</span>
                                </div>
                                <div class="col-4 col-md-3">
                                    <label>{{ $t('futures.order_stop_loss_point') }}</label>
                                    <span v-if="order.stopLossPoint > 0"> {{ order.stopLossPoint }}</span>
                                    <span v-else class="text-muted">--</span>
                                </div>

                                <template v-if="order.status !== 1 && order.status !== 0">
                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_close_price') }}</label>
                                        <div>{{ order.settlePrice }}</div>
                                    </div>
                                    <div class="col-4 col-md-3">
                                        <label>{{ $t('futures.order_profit') }}</label>
                                        <label>/USDT</label>
                                        <div>
                                            <b>
                                                <span :class="{ 'color-up': order.totalProfit >= 0, 'color-down': order.totalProfit < 0 }">{{ order.totalProfit.toFixed(2) }}</span>
                                            </b>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="row content-row">
                                <div class="col-6 col-md-3">
                                    <label>{{ $t('futures.order_create_time') }}</label> {{ new Date(order.timeCreated).formatDateTime() }}
                                </div>
                                <div class="col-6 col-md-3">
                                    <label>{{ $t('futures.order_settle_time') }}</label>
                                    {{ new Date(order.timeSettled).formatDateTime() }}
                                </div>
                            </div>
                        </div>

                        <!-- loader -->
                        <loading-indicator v-if="loading" />
                        <div class="row" v-if="!loading && hasMore">
                            <div class="col text-center">
                                <a href="javascript:" class="d-block text-muted p-3" v-on:click="getOrdersAsync">{{ $t('orders.query_more_orders') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            orders: null,
            hasMore: false,
            offset: 0,

            // Indicate if we're reading data from the server?
            loading: false
        };
    },

    created() {
        this.getOrdersAsync();
    },

    methods: {
        getOrdersAsync: async function () {
            try {
                ///////////////////////////////////////////////////////////////////////////////////
                // Read all orders
                ///////////////////////////////////////////////////////////////////////////////////
                this.loading = true;
                const json = await $.callPostApi(this, '/api/v1/futures/orders?offset=' + this.offset);
                if (typeof json === 'undefined') {
                    return; // Needs authentication
                }

                if (json.errcode === 0) {
                    if (!this.orders) {
                        this.orders = [];
                    }

                    for (let i = 0; i < json.data.length; i++) {
                        this.orders.push(json.data[i]);
                    }
                    this.offset = json.offset + json.data.length;
                    this.hasMore = json.data.length >= json.page_size;
                } else {
                    $.top_error(json.errmsg);
                }

                this.loading = false;
            } catch (err) {
                this.loading = false;
                $.top_error(this.$t('general.http_error'));
            }
        }
    }
};
</script>