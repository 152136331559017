<template>
    <section class="page settings-page">
        <div v-if="!profile">
            <loading-indicator />
        </div>
        <template v-else>
            <section class="top-header">
                <div class="container">
                    <div class="row align-items-end">
                        <div class="col ">
                            <div class="fs-1">
                                <!-- vip status -->
                                <div v-if="profile.vipLevel > 0" class="d-flex align-items-center vip-label">
                                    <svg class="svg-icon" viewBox="0 0 1024 1024" version="1.1" width="64" height="64">
                                        <path d="M760.6 863.5H304.7c-37.2 0-59.4-20.8-67.4-64.7l-57.8-489.7 186.2 120.2 171.8-231.1 173.4 231.1 175-120.2L828 798.8c-7.2 40.1-30.2 64.7-67.4 64.7z" fill="#FFE4B3"></path>
                                        <path d="M726.1 857.8H270.2c-31.9 0-72.5-13.7-84.4-78.9-0.1-0.4-0.1-0.7-0.2-1.1l-57.8-489.7c-0.8-6.6 2.3-13.1 8-16.7 5.7-3.5 12.9-3.5 18.5 0.1L327 382.9l162.1-218.2c3.3-4.4 8.4-7 13.8-7s10.6 2.6 13.8 6.9l163.4 217.9 161.3-110.8c5.6-3.8 12.9-4.1 18.7-0.5 5.8 3.5 9 10.1 8.2 16.8l-57.8 489.7c0 0.3-0.1 0.7-0.2 1-4.3 23.9-13.5 42.9-27.3 56.6-14.6 14.7-34.3 22.5-56.9 22.5z m-506.3-84.6c8.2 44.1 30 50 50.3 50H726c27.3 0 44.2-16.8 50.3-50l53.3-451.4L686 420.4c-7.6 5.2-18 3.5-23.6-3.9L503 204 345 416.5c-5.4 7.3-15.6 9.1-23.2 4.2L166.4 320.4l53.4 452.8z"></path>
                                        <path d="M495.8 706h-0.2c-6.4-0.1-12.2-3.7-15.2-9.4l-83.6-162.1c-4.4-8.5-1-18.9 7.4-23.3 8.5-4.4 18.9-1 23.3 7.4l68.7 133.1 72.7-133.5c4.6-8.4 15.1-11.5 23.4-6.9 8.4 4.6 11.5 15.1 6.9 23.4L511 697c-3.1 5.6-8.9 9-15.2 9z"></path>
                                    </svg>
                                    <div>{{ $t(profile.vipLevel > 7 ? 'user.vip10' : 'VIP' + profile.vipLevel) }}</div>
                                </div>
                                <div class="real-name">{{ profile.realName }}
                                    <div class="checkin-box" v-if="sysconfig.luckydraw && sysconfig.luckydraw.enabled"> 
                                        <button  type="button" class="btn btn-primary btn-sm btn-lucky" @click="luckyDraw()">{{$t("lucky.title")}}</button>
                                    </div>
                                </div>
                                
                            </div>

                            <!-- identity verification status -->
                            <!-- <div v-if="profile.requiredIDLevel > 0" class="mt-4">
                                <div>{{ $t('user.label_id_verification') }}</div>
                                <div class="fs-5">
                                    <p class="help-block">{{ $t('user.label_id_verification_desp') }}</p>
                                    <p v-if="profile.verifiedLevel >= profile.requiredIDLevel">
                                        <a href="javascript:void(0)" class="fs-3">
                                            <svg viewBox="0 0 256 256" class="icon-warning">
                                                <use xlink:href="/dist/svg/icons.svg#icon-checked" />
                                            </svg>
                                            {{ $t('user.label_id_verified') }}
                                        </a>
                                    </p>
                                    <p v-else-if="id_status === 1">
                                        <a href="javascript:;" class="fs-3">
                                            <svg viewBox="0 0 256 256" class="icon-warning">
                                                <use xlink:href="/dist/svg/icons.svg#icon-warning-sign" />
                                            </svg>
                                            {{ $t('user.label_id_waiting') }}
                                        </a>
                                    </p>
                                    <p v-else>
                                        <router-link to="/user/verifyid" class="fs-3">
                                            <svg viewBox="0 0 256 256" class="icon-warning">
                                                <use xlink:href="/dist/svg/icons.svg#icon-warning-sign" />
                                            </svg>
                                            {{ $t('user.label_id_incomplete') }}
                                        </router-link>
                                    </p>
                                </div>
                            </div> -->
                            <!-- <span>{{ $t('user.label_register_time') }} {{ new Date(profile.timeCreated).formatDate() }}</span> -->
                        </div>
                    </div>
                </div>
            </section>

            <div class="container setting-items">
                <div class="row">
                    <ul>
                        <li>
                            <router-link to="/user/verifyid" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('user.label_id_verification') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li v-if="sysconfig.phoneSupported">
                            <router-link to="/user/phone" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('register.label_phone') }}</div>
                                <div class="fw-normal me-2">{{ profile.phone }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li v-if="sysconfig.emailSupported">
                            <router-link to="/user/email" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('register.label_email') }}</div>
                                <div class="fw-normal me-2">{{ profile.email }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user/reset" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('user.label_password') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user/ga" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('ga.title') }}</div>
                                <div class="me-2">
                                    <span class="fw-normal" v-if="profile.isgaSet">{{ $t('ga.status_set') }}</span>
                                    <span v-else>{{ $t('ga.status_unset') }}</span>
                                </div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li v-if="sysconfig.requiresWithdrawPassword === true">
                            <router-link to="/user/changewithdrawpwd" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('user.label_financial_password') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user/bankaccounts" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('user.label_bankaccount') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user/balance" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('home.footer_assets') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/notices" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('notices.notice_title') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/exchange/orders" class="d-flex align-items-center">
                                <div class="flex-fill" v-if="$i18n.locale === 'en'">{{ $t('home.header_exchange_orders_en') }}</div>
                                <div class="flex-fill" v-else>{{ $t('home.header_exchange_orders') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/futures/openorders" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('home.header_futures_orders') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li v-if="sysconfig.bOptionEnabled">
                            <router-link to="/boption/orders" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('home.header_boption_orders') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/user/signout" class="d-flex align-items-center">
                                <div class="flex-fill">{{ $t('user.label_signout') }}</div>
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="page-part">
                <div class="container">
                    <div class="row top-icons">
                        <div class="col">
                            <div>{{ profile.realName }}</div>
                            <div>
                                {{ $t('user.balance_amount') }} <b>{{ balance }}</b> {{ currencySymbol }}
                            </div>
                        </div>
                        <div class="col text-right">
                            <router-link to="/finance/deposit" class="btn btn-primary">{{ $t('user.deposit') }}</router-link>
                            <router-link to="/finance/withdraw" class="btn btn-default">{{ $t('user.withdraw') }}</router-link>
                        </div>
                    </div>
                </div>
            </div> -->
        </template>
    </section>
</template>

<style scoped lang="less">
/* .settings-page {
    background: #f8f9fc;
    background: var(--bg-secondary);
} */

.top-header {
    color: var(--top-header-text);
    background: var(--top-header-bg);
    overflow: hidden;
}

.top-header::v-deep .spinner-border {
    border-width: 2px;
    opacity: 0.2;
}

.top-header .svg-icon {
    width: 2rem;
    height: 2rem;
    fill: currentColor;
}

.top-header .vip-label {
    font-size: 1.25rem;
    color: var(--text-vip);
    font-weight: 400;
    border-radius: 0.25rem;
}

.icon-warning {
    height: 2rem;
    width: 2rem;
}

a svg {
    width: 1rem;
    height: 1rem;
}

ul {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

ul>li {
    list-style-type: none;
}

li>a {
    display: block;
    background: var(--bg-secondary);
    color: var(--text-primary);
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid var(--text-secondary);
    border-radius: .5rem;
    font-weight: 600;
}

li>a:hover {
    color: var(--primary-hover)
}
.real-name {
    display: flex;
    .btn-lucky {
        margin-left: 20px;        
    }
}
</style>

<script>
import '@/assets/svg/icon-warning-sign.svg';
import '@/assets/svg/icon-checked.svg';
import '@/assets/svg/icon-arrow-right.svg';

import { setProfile } from 'utilities/helper';

export default {
    data() {
        return {
            profile: null,
            id_status: NaN
        };
    },

    created() {
        this.getProfileAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
    },

    methods: {
        luckyDraw(){
            this.$router.push({ path: '/luckyDraw' });
        },
        getProfileAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');

            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');
                    self.id_status = resp.data;
                }

                self.profile = profile;
            }
        }
    }
};
</script>