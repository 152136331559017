<template>
    <div class="content-top flex-fill">
        <!-- Import the symbols offset canvas -->
        <symbols-side-nav ref="symbolsSideNav" :symbol="symbol" />

        <div class="left">
            <div class="trading-chart">
                <!-- ticker -->
                <div class="ticker bg-title text-content">
                    <!--
                    FOR VERSION 4.4
                    -->
                    <template v-if="$version === '4.4'">
                        <div class="d-none d-md-block">
                            <!-- FOR LARGE SCREEN -->
                            <div class="d-flex flex-row align-items-center">
                                <div stop="1" class="symbol-name">
                                    <div class="small">{{ $t('general.boption') }}</div>
                                    <div class="dropdown-toggle" @click="showSideNav">{{ symbol.metadata.name }}</div>
                                </div>

                                <div growing-ignore="true" v-bind:class="'price-container ' + (symbol.up ? 'color-up' : 'color-down')">
                                    <div>{{ symbol.price }}</div>
                                    <div class="price-change">{{ symbol.price_change }}</div>
                                </div>
                                <dl growing-ignore="true" class="change ms-3">
                                    <dt class="text-label">{{ $t('boption.24h_change') }}</dt>
                                    <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                                </dl>
                                <dl growing-ignore="true" class="low ms-3">
                                    <dt class="text-label">{{ $t('boption.24h_low') }}</dt>
                                    <dd>{{ symbol.day_low }}</dd>
                                </dl>
                                <dl growing-ignore="true" class="high ms-3">
                                    <dt class="text-label">{{ $t('boption.24h_high') }}</dt>
                                    <dd>{{ symbol.day_high }}</dd>
                                </dl>
                                <!-- <dl growing-ignore="true" class="current-balance">
                                    <dt class="text-label">{{ $t('boption.label_balance') }}</dt>
                                    <dd class="color-up">{{ current_balance }}</dd>
                                </dl> -->
                            </div>
                        </div>
                        <div class="d-block d-md-none sm-trade-header">
                            <!-- FOR SMALL SCREENS -->
                            <div class="d-flex flex-row align-items-center">
                                <div class="d-flex flex-column">
                                    <div stop="1" class="symbol-name">
                                        <div class="small">{{ $t('general.boption') }}</div>
                                        <div class="dropdown-toggle" @click="showSideNav">{{ symbol.metadata.name }}</div>
                                    </div>

                                    <!-- Current price -->
                                    <div growing-ignore="true" class="price-container" :class="{ 'color-up': symbol.up, 'color-down': !symbol.up }">
                                        <div>{{ symbol.price }}</div>
                                        <div class="price-change">{{ symbol.price_change }}</div>
                                    </div>
                                </div>

                                <div>
                                    <dl growing-ignore="true" class="current-balance d-flex flex-row">
                                        <dt class="text-content me-2">{{ $t('boption.label_balance') }}</dt>
                                        <dd class="color-up">{{ current_balance }}</dd>
                                    </dl>
                                    <dl growing-ignore="true" class="low d-flex flex-row">
                                        <dt class="text-label me-2">{{ $t('futures.24h_low') }}</dt>
                                        <dd>{{ symbol.day_low }}</dd>
                                    </dl>
                                    <dl growing-ignore="true" class="high d-flex flex-row">
                                        <dt class="text-label me-2">{{ $t('futures.24h_high') }}</dt>
                                        <dd>{{ symbol.day_high }}</dd>
                                    </dl>
                                    <dl growing-ignore="true" class="amount d-flex flex-row">
                                        <dt class="text-label me-2">{{ $t('futures.24h_vol') }}</dt>
                                        <dd>{{ symbol.day_vol }}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div stop="1" class="symbol-name">
                            <div class="d-flex flex-row">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ $t('general.boption') }}
                                    </button>
                                    <div class="dropdown-menu">
                                        <router-link to="/futures" class="dropdown-item">{{ $t('general.futures') }}</router-link>
                                        <router-link to="/exchange" class="dropdown-item">{{ $t('general.exchange') }}</router-link>
                                        <router-link to="/boption" class="dropdown-item">{{ $t('general.boption') }}</router-link>
                                    </div>
                                </div>
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{ symbol.metadata.name }}
                                    </button>
                                    <div class="dropdown-menu">
                                        <router-link v-for="sym in symbols" :key="sym.id" :to="'/boption/' + sym.symbol.toLowerCase()" class="dropdown-item">
                                            {{ sym.name }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div growing-ignore="true" v-bind:class="'price-container ' + (symbol.up ? 'color-up' : 'color-down')">
                            <div>{{ symbol.price }}</div>
                            <div class="price-change">{{ symbol.price_change }}</div>
                        </div>
                        <dl growing-ignore="true" class="change d-none d-sm-block">
                            <dt class="text-label">{{ $t('boption.24h_change') }}</dt>
                            <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                        </dl>
                        <dl growing-ignore="true" class="low d-none d-md-block">
                            <dt class="text-label">{{ $t('boption.24h_low') }}</dt>
                            <dd>{{ symbol.day_low }}</dd>
                        </dl>
                        <dl growing-ignore="true" class="high d-none d-md-block">
                            <dt class="text-label">{{ $t('boption.24h_high') }}</dt>
                            <dd>{{ symbol.day_high }}</dd>
                        </dl>
                        <dl growing-ignore="true" class="current-balance">
                            <dt class="text-label">{{ $t('boption.label_balance') }}</dt>
                            <dd class="color-up">{{ current_balance }}</dd>
                        </dl>
                    </template>
                </div>

                <!-- order options -->
                <div class="container-fluid bg-title text-content selected-order-options">
                    <div class="row">
                        <div class="col-10">
                            <div class="row">
                                <div class="col text-center">
                                    <h2 class="text-label">{{ $t('boption.label_amount') }}</h2>
                                    <!-- {{ boptionOrder.amount }} -->

                                    <span v-if="boptionOrder.useCoupons === true">{{ $t('orders.label_currency_coupon') }}</span>
                                    <span v-else>{{ boptionOrder.amount }}</span>
                                </div>
                                <div class="col text-center">
                                    <h2 class="text-label">{{ $t('boption.label_duration') }}</h2>
                                    {{ boptionOrder.duration }}s
                                </div>
                                <div class="col text-center">
                                    <h2 class="text-label">{{ $t('boption.label_profit_rate') }}</h2>
                                    <b> {{ boptionOrder.profitRate }}</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 text-center">
                            <button class="btn btn-primary" v-on:click="showOrderOptions = true"><i class="fa fa-arrow-down"></i></button>
                        </div>
                    </div>
                </div>

                <!-- chart -->
                <div class="trading-chart-container bg-content">
                    <div class="chart-container">
                        <div id="tv_chart_container" class="chart">
                            <!-- Display the loading indicator by default -->
                            <loading-indicator />
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-trade bg-title">
                <div class="row">
                    <!-- <div class="col-6 text-label">
                        <a href="javascript:">
                            <div class="row">
                                <dl class="col">
                                    <dt>{{ $t('boption.label_amount') }}</dt>
                                    <dd>{{ boptionOrder.amount }}</dd>
                                </dl>
                                <dl class="col">
                                    <dt>{{ $t('boption.label_duration') }}</dt>
                                    <dd>{{ boptionOrder.duration }}s</dd>
                                </dl>
                                <dl class="col">
                                    <dt>{{ $t('boption.label_profit_rate') }}</dt>
                                    <dd>{{ boptionOrder.profitRate }}</dd>
                                </dl>
                                <div class="indicator">
                                    <i class="fas fa-angle-down"></i>
                                </div>
                            </div>
                        </a>
                    </div> -->

                    <!-- Create order buttons -->
                    <div class="col">
                        <button class="btn-submit bg-buy" @click="triggerCreateOrder(true)">{{ $t('boption.label_buy_up') }}</button>
                    </div>
                    <div class="col">
                        <button class="btn-submit bg-sell" @click="triggerCreateOrder(false)">{{ $t('boption.label_buy_down') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="micro-trade" :class="{ 'slide-out': !showOrderOptions }">
            <div class="mod bg-content">
                <div class="mod-head tabs bg-title text-label">
                    {{ $t('boption.label_balance') }}
                </div>
                <div class="mod-body current-balance text-content">
                    <div class="ticker text-content">
                        <dl growing-ignore="true" class="balance">
                            <dd class="color-up">
                                {{ current_balance }} <small>{{ currencySymbol }}</small>
                            </dd>
                        </dl>
                    </div>
                    <div class="actions">
                        <router-link to="/user/balance">{{ $t('general.deposit') }}</router-link>
                        <router-link to="/finance/withdraw">{{ $t('general.withdraw') }}</router-link>
                    </div>
                </div>
            </div>

            <!-- Order options -->
            <order-options :boptionConfig="boptionConfig" :boptionOrder="boptionOrder" @create-order="triggerCreateOrder" @close-options="onCloseOrderOptions" />
        </div>
    </div>
</template>

<style scoped>
@media (min-width: 1024px) {
    .selected-order-options {
        display: none;
    }
}

.selected-order-options {
    font-size: 1.5rem;
    line-height: 1.5;
}

.selected-order-options h2 {
    font-size: 1rem;
    margin: 0;
    line-height: 1.5;
}

.selected-order-options .btn {
    margin-top: 0.5rem;
    min-width: auto;
}
</style>

<script>
import { getSymbolManagerAsync } from 'utilities/helper';
import * as chart from 'utilities/QuoteDataFeed.js';
import { Dropdown } from 'bootstrap';

import OrderOptions from './BOption_OrderOptions.vue';
import SymbolsSideNav from '../../Components/_SymbolsSideNav.vue';

// The date time when the last quote is received.
let g_lastQuoteTime = new Date();

export default {
    components: { OrderOptions, SymbolsSideNav },
    props: ['symbol', 'boptionConfig', 'boptionOrder'],

    data() {
        return {
            // All binary option symbols
            symbols: [],

            // The current available balance.
            current_balance: '--',

            // Indicates whether to display order options or not (for mobile version)
            showOrderOptions: false,

            // The version of the current chart.
            chartVersion: 0,

            // Indicates whether the component has been destoyed or not.
            destroyed: false
        };
    },

    created() {
        this.destroyed = false;

        this.getSymbols();
        this.syncBalance();
    },

    mounted() {
        this.initChartAsync();

        const dropdownElementList = document.querySelectorAll('.dropdown .dropdown-toggle')
        const dropdownList = [...dropdownElementList].map(elm => {
            $(elm).click(() => {
                const d = Dropdown.getOrCreateInstance(elm);
                d.show();
            });
        });
    },

    beforeDestroy() {
        console.log(`## Destroy the binary option page.`);
        this.destroyed = true;

        // Stop socket io connections
        $.closeSocketIo();
        chart.destroyChart();
    },

    methods: {
        showSideNav: function () {
            this.$refs.symbolsSideNav.show()
        },

        initChartAsync: async function () {
            const sym = this.symbol;
            const self = this;

            // Clear any existing klines
            chart.setInitialKlines(null);

            // Read initial klines
            const end_ts = Math.floor(new Date().setSeconds(0, 0) / 1000);
            const from_ts = end_ts - 1500 * 60; // request klines for past 1500 minutes.
            const request_url = `/api/v1/quotation/klines?id=${encodeURIComponent(sym.metadata.id)}&type=1&from=${from_ts}&to=${end_ts}&limit=1500`;

            try {
                const resp = await $.callPostApi(self, request_url);
                if (resp) {
                    chart.setInitialKlines(resp);
                }
            } catch (err) {
                console.log(`Failed to read initial klines: ${err}`);
            }

            // Initialize the tradingview chart
            chart.initializeChart(sym, {
                region: this.sysconfig.region,
                locale: this.$i18n.locale,
                uiVersion: this.$version,
                defaultInterval: this.boptionConfig.defaultInterval,
                tzOffset: this.sysconfig.tzOffset
            });

            g_lastQuoteTime = new Date();
            const chartVersion = ++this.chartVersion;

            // Start a socket io connection
            $.initSocketIo('/m' + this.symbol.metadata.id, (quote) => {
                if (self.destroyed === false) {
                    if (self.chartVersion !== chartVersion) {
                        return;
                    }

                    // Is there a big gap for the latest quote?
                    let now = new Date();
                    const gap = now - g_lastQuoteTime;
                    if (gap > 300 * 1000) {
                        setTimeout(() => {
                            $.closeSocketIo();
                            self.initChartAsync();
                        }, 0);
                        return;
                    } else {
                        g_lastQuoteTime = now;

                        sym.updateRtqs(quote);
                        chart.updateRtqsToChart(quote);
                    }
                }
            });
        },

        getSymbols: function () {
            const self = this;
            getSymbolManagerAsync().then((mgr) => {
                // 1: boption symbols
                self.symbols = mgr.getSymbols(1);
            });
        },

        syncBalance: function () {
            const self = this;
            $.callPostApi(self, '/api/v1/balance/get').then((json) => {
                if (json) {
                    self.current_balance = (json.balance + json.frozen).toFixed(2);
                } else {
                    self.current_balance = '--';
                }
            });
        },

        triggerCreateOrder: function (direction) {
            this.showOrderOptions = false;
            this.$emit('create-order', direction);
        },

        onCloseOrderOptions: function () {
            this.showOrderOptions = false;
        }
    }
};
</script>