<template>
    <section class="page posts-page">
        <loading-indicator v-if="!categories" />
        <template v-else>
            <div v-for="(c, index) in categories" :key="index" class="container">
                <div class="row">
                    <div class="col">
                        <h1>
                            {{ c.category.name }}
                            <router-link :to="`/college/${c.category.id}`" class="float-end d-flex align-items-center">
                                {{ $t('home.label_more') }}
                                <svg width="1em" height="1em" viewBox="0 0 1024 1024">
                                    <path d="M128 469.333333h604.586667l-152.746667-153.173333L640 256l256 256-256 256-60.16-60.16L732.586667 554.666667H128z"></path>
                                </svg>
                            </router-link>
                        </h1>
                    </div>
                </div>
                <div v-if="c.items" class="row">
                    <!-- posts within the category. -->
                    <posts-component :posts="c.items" />
                </div>
            </div>
        </template>

    </section>
</template>

<script lang="js">
import PostsComponent from './Components/_Posts.vue';

export default {
    components: { PostsComponent },

    data() {
        return {
            categories: null
        };
    },

    watch: {
        /**
         * Update post content after the language is changed.
         */
        '$i18n.locale': function (newVal, oldVal) {
            this.initPageAsync();
        }
    },

    mounted() {
        this.initPageAsync();
    },

    methods: {
        initPageAsync: async function () {
            const json = await $.callPostApi(this, '/api/v1/post/college');
            if (json && json.errcode === 0) {
                this.categories = json.data;
            }
        }
    }
};
</script>