<template>
    <form method="post" action="javascript:void(0)" @submit="submitChange">
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <label for="Password"> {{ $t('withdraw_password.label_new_password') }} </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="pwd-box">
                        <input name="WithdrawPassword" maxlength="6" autocomplete="new-password" type="password" class="form-control" pattern="\d*" inputmode="decimal" data-val="true" data-val-required="*" data-val-regex-pattern="^\d{6}$" data-val-regex="*" data-val-maxlength="*" data-val-maxlength-max="6" data-val-minlength="*" data-val-minlength-min="6" :type="showPassword ? 'text' : 'password'" />
                        <svg viewBox="0 0 256 256"
                            class="svg-icon showPwd-icon" @click="showPassword = !showPassword"
                            :class="showPassword ? 'active' : ''">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-displayPassword" />
                            </svg>
                        </div>
                    <div class="help-block">{{ $t('withdraw_password.label_new_password_desp') }}</div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <label for="ConfirmPassword">{{ $t('withdraw_password.label_confirm_password') }}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">

                    <div class="pwd-box">
                        <input name="ConfirmWithdrawPassword" maxlength="6" autocomplete="new-password" type="password" class="form-control" data-val="true" data-val-required="*" data-val-regex-pattern="^\d{6}$" data-val-regex="*" data-val-maxlength="*" data-val-maxlength-max="6" data-val-minlength="*" data-val-minlength-min="6" data-val-equalto-other="*.WithdrawPassword" data-val-equalto="*" :type="PasswordConfirm ? 'text' : 'password'" />
                                        <svg viewBox="0 0 256 256" class="svg-icon showPwd-icon"
                                            @click="PasswordConfirm = !PasswordConfirm"
                                            :class="PasswordConfirm ? 'active' : ''">
                                            <use xlink:href="/dist/svg/icons.svg#v4.2-displayPassword" />
                                        </svg>
                                    </div>
                </div>
            </div>
        </div>

        <!-- sms verification -->
        <sms-verification-component useAccountKey="true" action="send" :emailVCode="emailVCode" :isWithdrawPassword="true" :hasEmail="hasEmail" @changeType="changeType"/>

        <div class="form-group">
            <div class="row">
                <div class="col-md-6">
                    <button type="submit" class="btn-submit btn-block" :class="{ 'btn-loading': submitting }" :disabled="submitting">
                        {{ $t('general.submit') }}
                    </button>
                    <a v-if="allow_skip" href="javascript:;" class="btn btn-link" @click="skipChange">{{ $t('withdraw_password.label_skip') }}</a>
                </div>
            </div>
        </div>
    </form>
</template>
<style scoped>
.pwd-box {
    position: relative;
}

.pwd-box .showPwd-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
}

.showPwd-icon.active {
    fill: var(--primary-base);
    stroke: var(--primary-base);
}
</style>
<script>
import SmsVerificationComponent from '../../Components/SmsVerification.vue';
import '@/assets/svg/v4.2/v4.2-displayPassword.svg'
export default {
    components: { SmsVerificationComponent },

    props: ['profile'],

    data() {
        return {
            submitting: false,
            allow_skip: false,

            // Indicates whether to use email or sms verification code.
            emailVCode: false,
            hasEmail: false,
            type:'Phone',
            showPassword: false,
            PasswordConfirm: false
        };
    },

    mounted() {
        this.allow_skip = this.$route.query.skip === 'true';
        this.emailVCode = this.profile.useEmailVCode;
        this.hasEmail = !!this.profile.email;
        $.resetValidators();
    },

    methods: {
        changeType(type){
            this.type = type
        },
        submitChange: function (e) {
            const self = this;

            var frm = $(e.target);
            if (frm.valid()) {
                // post the server
                this.submitting = true;
                this.$http
                    .post(g_server_root + '/api/v1/account/changewithdrawpassword', frm.serialize()+'&method='+this.type)
                    .then((resp) => {
                        const json = resp.data;
                        if (json && json.errcode == 0) {
                            $.top_alert(self.$t('general.operation_succeeded'));

                            if (self.allow_skip) {
                                setTimeout(function () {
                                    self.$router.push('/user');
                                }, 500);
                            } else {
                                frm.trigger('reset');
                            }
                        } else {
                            $.top_error(json.errmsg || self.$t('general.operation_error'));
                        }
                    })
                    .catch((err) => {
                        console.error('ERROR: ' + err);
                        $.top_error(self.$t('general.operation_error'));
                    })
                    .then(() => {
                        this.submitting = false;
                    });
            }
        },

        skipChange: function () {
            this.$router.push('/user');
        }
    }
}
</script>