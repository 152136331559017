<template>
    <section class="page startup-page support-iframe">
        <!-- page title -->
        <div class="page-top">
            <div class="container">
                <div class="row">
                    <div class="col startup-title">
                        <h1>{{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}</h1>
                        <div class="tips" v-if="context.profile && context.profile.groups && context.profile.groups.length">
                            {{sysconfig.groups[$i18n.locale.toLowerCase()][context.profile.groups[0]]}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="pro_layout container">
            <!-- <h1>{{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}</h1> -->

            <ul class="page-top-nav mb-3">
                <!-- <li :class="{ active: !ongoing_tokens }">
                    <a href="javascript:;" @click="ongoing_tokens = false">{{ $t('startup.title_forecast') }}</a>
                </li> -->
                <li :class="{ active: ongoing_tokens }">
                    <a href="javascript:;" @click="ongoing_tokens = true">{{ $t('startup.title_ongoing') }}</a>
                </li>
                <li>
                    <router-link to="/startup/orders">{{ $t('startup.title_orders') }}</router-link>
                </li>
            </ul>

            <!-- Display a list of tokens -->
            <token-list :ongoing_tokens="ongoing_tokens" />
        </section>

        <ext-resource-i-frame />
    </section>
</template>



<script>
import ExtResourceIFrame from '../Components/ExtResourceIFrame.vue';
import TokenList from './Components/TokenList.vue';
import { getAppContext } from 'utilities/helper';

export default {
    components: { ExtResourceIFrame, TokenList },

    data() {
        return {
            // Display on-going  or forecast tokens?
            ongoing_tokens: true,
            context: null
        };
    },
    created() {
        this.context = getAppContext();
    },
};
</script>