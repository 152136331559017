
import axios from 'axios';
import { createSymbolManagerAsync } from './SymbolStore.js';


// Start with an empty profile
const appContext = {
    profile: null,
    symbolManager: null,
    homeConfig: null
};


/**
 * ======================================================================================
 * @returns An app context.
 */
export function getAppContext() {
    return appContext;
}


/**
 * ======================================================================================
 * @returns Returns a symbol manager.
 */
export async function getSymbolManagerAsync() {
    let mgr = appContext.symbolManager;
    if (!mgr) {
        mgr = await createSymbolManagerAsync();
        appContext.symbolManager = mgr;
    }

    return mgr;
}


/**
 * ======================================================================================
 * @param {object} p User profile. 
 */
export function setProfile(p) {
    // Either the phone number or the email address must be specified.
    if (!p || ((!p.phone || typeof p.phone !== 'string') && (!p.email || typeof p.email !== 'string'))) {
        throw new Error('An invalid profile.');
    }
    appContext.profile = Object.freeze(p);
}


/**
 * ======================================================================================
 * Clear existing profile.
 */
export function clearProfile() {
    appContext.profile = null;
}


/**
 * ======================================================================================
 * Read an array from local storage using the specified key
 * @param key
 */
export function readArrayFromStorage(key) {
    try {
        const json = localStorage.getItem(key);
        if (json) {
            let arr = JSON.parse(json);
            if (arr && Array.isArray(arr) && arr.length > 0) {
                return arr;
            }
        }
    } catch (err) {
        console.log('Failed to read ' + key + ' from local storage');
    }
}


/**
 * ======================================================================================
 * Returns system configuration
 */
export async function getConfigAsync(force_update) {
    if (!force_update) {
        // Read cached config from localStorage first
        const data = localStorage.getItem('config');
        if (data) {
            try {
                const cached = JSON.parse(data);
                if (cached && typeof cached.time_expired === 'number' && cached.time_expired > new Date().valueOf()) {
                    cached.config.is_uk = cached.config.region === 'hk';
                    cached.config.is_hkmy = cached.config.region === 'my' || cached.config.region === 'hk';
                    const config = Object.freeze(cached.config);
                    $.setConfig(config);

                    return config;
                }
            } catch (err) {
                // Ignore errors
                console.error(`Failed to parse json: ${data}`);
            }
        }
    }

    // Read config from the server
    const resp = await axios.get(g_server_root + '/api/v1/config');
    const json = resp.data;
    if (json) {
        // Allow cache for 5 minutes.
        json.is_hk = json.region === 'hk';
        json.is_hkmy = json.region === 'my' || json.region === 'hk';
        const config = Object.freeze(json);
        $.setConfig(config);

        localStorage.setItem('config', JSON.stringify({ config: config, time_expired: new Date().valueOf() + 60 * 1000 }));
        return config;
    }
    throw new Error('Invalid system configuration.');
}


/**
 * ======================================================================================
 * Returns config data for the home page.
 */
export async function getHomeConfigAsync(lang) {
    // Read cached config from localStorage first
    const data = localStorage.getItem('home_config');
    const expectedRCode = appContext.profile ? appContext.profile.referralCode : 'NA';
    if (data) {
        try {
            const cached = JSON.parse(data);
            if (cached
                && cached.lang === lang // is the same language?
                && typeof cached.time_expired === 'number'
                && cached.time_expired > new Date().valueOf()
                && expectedRCode === cached.config.rcode) {
                const config = Object.freeze(cached.config);
                appContext.homeConfig = config;
                return config;
            }
        } catch (err) {
            // Ignore errors
            console.error(`Failed to parse json: ${data}`);
        }
    }

    // Read config from the server
    const resp = await axios.get(g_server_root + '/api/v1/home?lang=' + encodeURIComponent(lang));
    const json = resp.data;
    if (json && json.data) {
        // Is the config fetched  before log-in?
        json.data.rcode = appContext.profile ? appContext.profile.referralCode : 'NA'
        
        // Allow cache for 5 minutes.
        const config = Object.freeze(json.data);
        localStorage.setItem('home_config', JSON.stringify({
            lang: lang,
            config: config,
            time_expired: new Date().valueOf() + 60 * 1000
        }));

        appContext.homeConfig = config;
        return config;
    }
    throw new Error('Invalid settings for the home page.');
}


/**
 * ======================================================================================
 * Select a notice which were configured for popup.
 */
function checkNoticePopup(state, notice, today) {
    const nid = notice.id;
    switch (notice.popupMode) {
        // Once
        case 1:
            return !state[nid];

        // Daily
        case 2:
            const last_date = state[nid] * 1;
            if (isNaN(last_date) || last_date < today)
                return true;
            break;

        // Everytime.
        case 3:
            return true;
    }

    // Not eligible for popup.
    return false;
}

function readPopupState() {
    // Read popup state from local storage
    const json = localStorage.getItem('popup_notices');
    if (json) {
        try {
            return JSON.parse(json) || {};
        } catch (err) {
            // Ignore errors
            console.error(`Failed to parse popup state: ${data}`);
        }
    }

    return {};
}

function getLocalDate() {
    // Assume using +08:00 timezone.
    let local = new Date(new Date().valueOf() + 8 * 3600 * 1000);

    // Only cares about year, month, and date
    return local.getUTCFullYear() * 10000 + local.getUTCMonth() * 100 + local.getUTCDate();
}

export function scanPopupNotices(notices) {
    if (Array.isArray(notices) && notices.length > 0) {
        // Read popup state from local storage
        const state = readPopupState();
        const today = getLocalDate();
        const now_ts = new Date().valueOf();

        for (let i = 0; i < notices.length; i++) {
            const entity = notices[i];
            if (typeof entity.popupMode === 'number'
                && entity.popupMode > 0
                && entity.popupFrom <= now_ts
                && entity.popupTo > now_ts) {
                // The notice could be popped up.
                if (checkNoticePopup(state, entity, today)) {
                    // Save popup state.
                    try {
                        state[entity.id] = today;
                        localStorage.setItem('popup_notices', JSON.stringify(state));
                    } catch (err) {
                        console.log(`Failed to save popup state: ${err}`);
                    }

                    return entity;
                }
            }
        }
    }

    // Not found.
    return null;
}