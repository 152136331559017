<template>
  <div class="page flex-col">
  <div class="user-count-box justify-between">
    <div class="count-box flex-col justify-between">
      <span class="count"> <span>{{ $t('home_Info.all') }}</span> 10000000+ </span>
      <span class="paragraph">{{ $t('home_Info.user_choose') }}</span>
    </div>
    <div class="d-md-none d-block">
          <div class="register-box flex-col justify-between">
              <div class="reg-container flex-row justify-between">
                  <div class="text-wrapper_2 flex-col d-none d-md-block">
                      <input type="text" :placeholder="$t('home_Info.email_phone')" class="register-input" />
                  </div>
                  <div class="go-reg-box flex-col" @click="goto('/user/create')">
                      <span class="go-register">{{ $t('home_Info.register') }}</span>
                  </div>
                  </div>
                  <div class="gift-box flex-row justify-between">
                      <img
                      class="gift"
                      referrerpolicy="no-referrer"
                      src="@/assets/images/homeInfo/gift.png"
                  />
                  <span class="register-tips">{{ $t('home_Info.register_tips') }}</span>
              </div>
          </div>
      </div>
    <div class="quotes-box flex-col">
      <div class="quotes-item flex-row" v-for="(symbol, index) in filteredSymbols" :key="index">
          <div class="left-quotes">
              <div class="coin-box">
                  <token-icon class="thumbnail_1" :symbol="symbol"/>
                  <span class="coin-name">{{ symbol.metadata.name }}</span>
              </div>
              <span class="text_4">${{ symbol.price }}</span>
          </div>
          <span
              class="change-pct" 
              :class="{'down':parseFloat(symbol.price_change_pct) < 0,'up':parseFloat(symbol.price_change_pct) >= 0}">
                  {{symbol.price_change_pct }}
          </span>
      </div>
      <div class="more-quotes flex-row justify-between" @click="goto('/quotes')">
        <span class="all-coins">{{ $t('home_Info.view_all_coins') }}</span>
        <img
          class="next"
          referrerpolicy="no-referrer"
          src="@/assets/images/homeInfo/next.png"
        />
      </div>
    </div>
  </div>
  <div class="notices-box justify-between">
      <div class="d-none d-md-block">
          <div class="register-box flex-col justify-between">
              <div class="reg-container flex-row justify-between">
              <div class="text-wrapper_2 flex-col d-none d-md-block">
                  <input type="text" :placeholder="$t('home_Info.email_phone')" class="register-input" />
              </div>
              <div class="go-reg-box flex-col" @click="goto('/user/create')">
                  <span class="go-register">{{ $t('home_Info.register') }}</span>
              </div>
              </div>
              <div class="gift-box flex-row justify-between">
                  <img
                  class="gift"
                  referrerpolicy="no-referrer"
                  src="@/assets/images/homeInfo/gift.png"
              />
              <span class="register-tips">{{ $t('home_Info.register_tips') }}</span>
              </div>
          </div>
      </div>
    <div class="notices-right-box flex-col">
      <span class="notices-item" v-for="item in all_notices" :key="item.id">{{ item.title }}</span>
      <div class="view-more flex-row justify-between" @click="goto('/notices')">
        <span class="all-coins">{{ $t('home_Info.more') }}</span>
        <img
          class="next"
          referrerpolicy="no-referrer"
          src="@/assets/images/homeInfo/next.png"
        />
      </div>
    </div>
  </div>
  <div class="d-none d-md-block">
      <div class="register-type flex-row ">
      <div class="box_6 flex-col justify-between">
          <span class="or-register">{{ $t('home_Info.or_register') }}</span>
          <div class="image-wrapper_1 flex-row justify-between">
              <img
                  class="register-list"
                  referrerpolicy="no-referrer"
                  src="@/assets/images/homeInfo/google.png"
              />
              <img
                  class="register-list"
                  referrerpolicy="no-referrer"
                  src="@/assets/images/homeInfo/apple.png"
              />
          </div>
      </div>
      <div class="box_7 flex-col justify-between">
          <span class="text_25">{{ $t('home_Info.app_download') }}</span>
          <img
          class="register-list qr-code"
          referrerpolicy="no-referrer"
          src="@/assets/images/homeInfo/qr-code.png"
          />
      </div>
      <!-- <div class="box_8 flex-col">
          <div class="group_2 flex-row justify-between">
          <span class="text_26">比特币减半倒计时</span>
          <img
              class="thumbnail_8"
              referrerpolicy="no-referrer"
              src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngddfd182b0143f2aab3523db6c0b286629fa2c6f25b9e2134d611e2564de626b3"
          />
          </div>
          <div class="group_3 flex-row">
          <div class="text-wrapper_4">
              <span class="text_27">60</span> <span class="text_28">D</span>
          </div>
          <div class="text-wrapper_5">
              <span class="text_29">20</span> <span class="text_30">H</span>
          </div>
          <div class="text-wrapper_6">
              <span class="text_31">15</span> <span class="text_32">M</span>
          </div>
          <div class="text-wrapper_7">
              <span class="text_33">20</span> <span class="text_34">S</span>
          </div>
          </div>
      </div> -->
      </div>
  </div>
  <div class="bottom-box justify-between">
    <div class="app-img-box flex-col justify-between">
          <img class="show-img" src="@/assets/images/homeInfo/app-page.png" alt="">
        <!-- <div class="text-group_3 flex-row justify-between">
          <span class="app-type">{{ $t('home_Info.desk') }}</span>
          <span class="app-type type-active">{{ $t('home_Info.simple') }}</span>
          <span class="app-type">{{ $t('home_Info.major') }}</span>
        </div> -->
      <!-- <div class="box_9 flex-col"><div class="section_1 flex-col"></div></div> -->
    </div>
    <div class="download-box flex-col">
      <span class="exchange-tips">{{ $t('home_Info.exchange_tips') }}</span>
      <div class="d-none d-md-block">
          <div class="block_8 flex-row justify-between ">
          <div class="group_7 flex-col">
              <div class="qr-code-box flex-col"></div>
          </div>
          <div class="text-group_4 flex-col justify-between">
              <span class="text_39">{{ $t('home_Info.qr_download_app') }}</span>
              <span class="text_40">{{ $t('home_Info.ios_android') }}</span>
          </div>
          </div>
      </div>
      <div class="d-none d-md-block">
          <div class="block_9 flex-row justify-between ">
              <div class="image-text_4 flex-col justify-between">
                  <img
                      class="system"
                      referrerpolicy="no-referrer"
                      src="@/assets/images/homeInfo/apple.png"
                  />
                  <span class="text-group_5">MacOS</span>
              </div>
              <div class="image-text_5 flex-col justify-between">
                  <img
                      class="system"
                      referrerpolicy="no-referrer"
                      src="@/assets/images/homeInfo/windows.png"
                  />
                  <span class="text-group_6">Windows</span>
              </div>
              <div class="image-text_6 flex-col justify-between">
                  <img
                      class="system"
                      referrerpolicy="no-referrer"
                      src="@/assets/images/homeInfo/linux.png"
                  />
                  <span class="text-group_7">Linux</span>
              </div>
          </div>
      </div>
      <div class="d-none d-md-block">
          <div class="image-text_7 flex-row justify-between ">
              <img
                  class="download"
                  referrerpolicy="no-referrer"
                  src="@/assets/images/homeInfo/download.png"
              />
          <span class="text-group_8">{{ $t('home_Info.more_download') }}</span>
          </div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import TokenIcon from '../../Components/TokenIcon.vue';
import { getAppContext } from 'utilities/helper';

export default {
  components: { TokenIcon },
  props: ['symbols'],

  data() {
      return {
          all_notices:null
      };
  },

  created() {
      this.readAllNotices()
  },

  computed: {
      filteredSymbols: function () {
          if (!this.symbols) {
              return [];
          }

          // Determines if test mode is enabled or not for the current user.
          const context = getAppContext();
          let testMode = false;
          if (context && context.profile) {
              testMode = context.profile.testEnabled === true;
          }

          const self = this;
              // Return symbols for the specified type.
          return this.symbols.filter((s) => {
              return s.metadata.type === 3 && (testMode || !s.metadata.testOnly);
          }).slice(0,4);
      }
  },

  methods: {
      readAllNotices() {
          this.all_notices = null;
          $.callGetApi(this, '/api/v1/notices').then((json) => {
              if (json.errcode === 0 && json.data) {
                  this.all_notices = Object.freeze(json.data).slice(0,3);
              }
          });
      },
      goto (url) {
          if (this.$route.path != url) {
              this.$router.push(url);
          }
      },
  }
};
</script>
<style scoped>
.page {
background-color: rgba(23, 26, 33, 1);
position: relative;
overflow: hidden;
justify-content: flex-center;
}

.user-count-box {
width: 760px;
margin: 60px 0 0 0;
display: flex;
justify-content: space-between;
}

.count-box {
width: 300px;
}

.count {
width: 440px;
overflow-wrap: break-word;
color: rgba(252, 214, 52, 1);
font-size: 48px;
letter-spacing: 3px;
font-family: DecoTypeNaskh;
font-weight: normal;
text-align: left;
white-space: normal;
word-break: break-word;
line-height: 1.2;
display: block;
}

.paragraph {
color: rgba(235, 236, 239, 1);
font-size: 38px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
line-height: 44px;
margin-top: 12px;
display: inline-block;
}

.quotes-box {
background-color: rgba(30, 35, 42, 1);
border-radius: 8px;
height: 146px;
width: 320px;
}

.quotes-item {
height: 18px;
margin: 10px 10px 0 10px;
display: flex;
justify-content: space-between;
}

.left-quotes{
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.coin-box{
  display: flex;
  justify-content: flex-start;
}
.thumbnail_1 {
width: 18px;
height: 18px;
}

.coin-name {
width: 21px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin: 2px 0 0 4px;
}

.text_3 {
width: 33px;
height: 14px;
overflow-wrap: break-word;
color: rgba(131, 141, 155, 1);
font-size: 10px;
font-weight: normal;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin: 2px 0 0 4px;
}

.text_4 {
width: 52px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin-left: 50px;
}

.change-pct {
width: 35px;
height: 14px;
overflow-wrap: break-word;
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin-left: 62px;
color: #fff;
}

.down{
  color: rgba(236, 69, 91, 1);
}

.up{
  color: rgba(13, 204, 130, 1);
}

.more-quotes {
width: 79px;
height: 16px;
margin: 9px 0 9px 10px;
display: flex;
cursor: pointer;
align-items: center;
}

.all-coins {
height: 14px;
overflow-wrap: break-word;
color: rgba(131, 141, 155, 1);
font-size: 10px;
letter-spacing: 0.38461539149284363px;
font-weight: normal;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin-top: 1px;
}

.next {
  width: 16px;
  height: 16px;
}

.notices-box {
width: 760px;
margin: 10px 0 0 0;
display: flex;
justify-content: space-between;
}

.register-box {
width: 278px;
height: 58px;
margin-top: 16px;
}

.reg-container {
width: 278px;
height: 30px;
display: flex;
}

.text-wrapper_2 {
width: 170px;
}

.register-input {
background-color: rgba(23, 26, 33, 1);
font-size: 12px;
height: 30px;
box-shadow: none;
border: 1px solid #2B3139;
width: 100%;
padding-left: 4px;
color: #fff;
border-radius: 4px;
}

.go-reg-box {
background-color: rgba(252, 214, 52, 1);
border-radius: 4px;
height: 30px;
margin-left: 8px;
display: flex;
justify-content: center;
align-items: center;
padding: 0 8px;
}

.go-register {
height: 18px;
overflow-wrap: break-word;
color: rgba(23, 26, 33, 1);
font-size: 10px;
font-family: PingFangSC-Medium;
font-weight: 500;
text-align: left;
white-space: nowrap;
line-height: 18px;
}

.gift-box {
width: 183px;
height: 16px;
margin-top: 12px;
display: flex;
}

.gift {
width: 16px;
height: 16px;
}

.register-tips {
width: 163px;
height: 16px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-family: PingFangSC-Medium;
font-weight: 500;
text-align: left;
white-space: nowrap;
line-height: 16px;
}

.notices-right-box {
background-color: rgba(30, 35, 42, 1);
border-radius: 8px;
width: 320px;
justify-content: flex-center;
}

.notices-item {
width: 230px;
height: 14px;
overflow: hidden;
text-overflow: ellipsis;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-weight: normal;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin: 10px 0 0 10px;
display: inline-block;
}

.view-more {
width: 58px;
height: 16px;
margin: 10px 0 10px 10px;
display: flex;
}

.register-type {
width: 760px;
height: 58px;
margin: 12px 0 0 0;
display: flex;
justify-content: start;
}

.box_6 {
width: 90px;
height: 56px;
margin-top: 2px;
}

.or-register {
width: 90px;
height: 16px;
overflow-wrap: break-word;
color: rgba(117, 126, 137, 1);
font-size: 10px;
font-weight: normal;
text-align: left;
white-space: normal;
line-height: 16px;
}

.image-wrapper_1 {
width: 64px;
height: 28px;
margin-top: 12px;
}

.register-list {
width: 28px;
height: 28px;
}

.box_7 {
width: 39px;
height: 56px;
margin: 2px 0 0 22px;
}

.text_25 {
width: 39px;
height: 16px;
overflow-wrap: break-word;
color: rgba(117, 126, 137, 1);
font-size: 10px;
font-weight: normal;
text-align: left;
white-space: nowrap;
line-height: 16px;
}

.qr-code {
margin-top: 12px;
}

.box_8 {
background-color: rgba(30, 35, 42, 1);
border-radius: 8px;
height: 58px;
margin-left: 249px;
width: 320px;
}

.group_2 {
width: 100px;
height: 16px;
margin: 9px 0 0 11px;
}

.text_26 {
width: 84px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
letter-spacing: 0.38461539149284363px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin-top: 1px;
}

.thumbnail_8 {
width: 16px;
height: 16px;
}

.group_3 {
height: 14px;
margin: 9px 0 10px 11px;
display: flex;
}

.text-wrapper_4 {
width: 23px;
height: 14px;
overflow-wrap: break-word;
font-size: 0;
letter-spacing: 0.38461539149284363px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.text_27 {
width: 23px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.text_28 {
width: 23px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 8px;
font-weight: normal;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.text-wrapper_5 {
width: 23px;
height: 14px;
overflow-wrap: break-word;
font-size: 0;
letter-spacing: 0.38461539149284363px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin-left: 17px;
}

.text_29 {
width: 23px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.text_30 {
width: 23px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 8px;
font-weight: normal;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.text-wrapper_6 {
width: 22px;
height: 14px;
overflow-wrap: break-word;
font-size: 0;
letter-spacing: 0.38461539149284363px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin-left: 17px;
}

.text_31 {
width: 22px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.text_32 {
width: 22px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 8px;
font-weight: normal;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.text-wrapper_7 {
width: 22px;
height: 14px;
overflow-wrap: break-word;
font-size: 0;
letter-spacing: 0.38461539149284363px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin-left: 15px;
}

.text_33 {
width: 22px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.text_34 {
width: 22px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 8px;
font-weight: normal;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.bottom-box {
  width: 760px;
margin: 100px 0 60px 0;
display: flex;
justify-content: space-around;
}

.app-img-box {
height: 319px;
}

.show-img {
border-radius: 16px;
width: 146px;
height: 280px;
}

.text-group_3 {
width: 110px;
height: 14px;
margin: 20px 0 0 19px;
display: flex;
}

.app-type {
height: 14px;
overflow-wrap: break-word;
color: rgba(117, 126, 137, 1);
font-size: 10px;
font-family: PingFangSC-Medium;
font-weight: 500;
text-align: right;
white-space: nowrap;
line-height: 14px;
margin-right: 10px;
}

.type-active{
  color: rgba(232, 233, 236, 1);
}

.box_9 {
background-color: rgba(43, 49, 57, 1);
height: 1px;
width: 120px;
margin: 4px 0 0 13px;
}

.section_1 {
background-color: rgba(252, 214, 52, 1);
width: 40px;
height: 1px;
margin-left: 40px;
}

.download-box {
width: 310px;
}

.exchange-tips {
height: 37px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 26px;
letter-spacing: 1px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: normal;
line-height: 37px;
}

.block_8 {
width: 207px;
height: 120px;
margin-top: 40px;
display: flex;
justify-content: space-between;
}

.group_7 {
border-radius: 8px;
height: 120px;
border: 1px solid rgba(43, 49, 57, 1);
width: 120px;
}

.qr-code-box {
border-radius: 8px;
background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae56c744ee264bdbb524c217a4b4f5ae_mergeImage.png);
width: 104px;
height: 104px;
border: 1px solid rgba(151, 151, 151, 1);
margin: 8px 0 0 8px;
}

.text-group_4 {
width: 69px;
height: 32px;
margin-top: 44px;
}

.text_39 {
width: 69px;
height: 14px;
overflow-wrap: break-word;
color: rgba(117, 126, 137, 1);
font-size: 10px;
font-weight: normal;
text-align: left;
white-space: nowrap;
line-height: 14px;
}

.text_40 {
width: 57px;
height: 14px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin-top: 4px;
}

.block_9 {
width: 252px;
height: 62px;
margin-top: 20px;
display: flex;
}

.image-text_4 {
width: 44px;
height: 62px;
}

.system {
width: 44px;
height: 44px;
}

.text-group_5 {
width: 35px;
height: 14px;
overflow-wrap: break-word;
color: rgba(255, 255, 255, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin: 4px 0 0 4px;
}

.image-text_5 {
width: 44px;
height: 62px;
margin-left: 60px;
}

.text-group_6 {
width: 44px;
height: 14px;
overflow-wrap: break-word;
color: rgba(255, 255, 255, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin-top: 4px;
}

.image-text_6 {
width: 44px;
height: 62px;
margin-left: 60px;
}

.text-group_7 {
width: 26px;
height: 14px;
overflow-wrap: break-word;
color: rgba(255, 255, 255, 1);
font-size: 10px;
font-family: PingFangSC-Semibold;
font-weight: 600;
text-align: left;
white-space: nowrap;
line-height: 14px;
margin: 4px 0 0 9px;
}

.image-text_7 {
width: 80px;
height: 16px;
margin-top: 24px;
display: flex;
}

.download {
width: 16px;
height: 16px;
}

.text-group_8 {
width: 60px;
height: 16px;
overflow-wrap: break-word;
color: rgba(232, 233, 236, 1);
font-size: 10px;
font-family: PingFangSC-Medium;
font-weight: 500;
text-align: left;
white-space: nowrap;
line-height: 16px;
}

@media (max-width: 768px) {
  .user-count-box{
      margin-top: 40px;
  }
  .user-count-box,.notices-box{
      flex-direction: column;
      width: 100%;
      align-items: center;
  }
  .count-box{
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .count{
      text-align: center;
      width: 100%;
  }
  .count,.paragraph{
      font-size: 30px;
      text-align: center;
  }
  .view-more,.more-quotes{
      width: 100%;
      justify-content: center;
      margin-left: 0;
  }
  .go-reg-box,.reg-container{
      height: auto;
  }
  .reg-container{
      justify-content: center;
  }
  .register-box{
      height: auto;
  }
  .register-tips{
      font-size: 12px;
      white-space: normal;
      height: auto;
      width: auto;
      text-align: center;
  }
  .gift-box{
      margin: 20px auto;
      height: auto;
      width: 100%;
      justify-content: center;
  }
  .go-register{
      padding: 12px 52px;
      height: auto;
      font-size: 16px;
  }
  .exchange-tips{
      font-size: 30px;
      padding: 0 8px;
  }
  .bottom-box{
      flex-direction: column-reverse;
      width: 100%;
      align-items: center;
      margin: 40px 0 28px;
  }
  .download-box{
      width: auto;
      margin-bottom: 20px;
  }
  .app-img-box{
      height: auto;
  }
  .show-img{
      width: 198px;
      height: auto;
  }
}

</style>