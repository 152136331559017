<template>
    <section class="page settings-page">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>{{ $t('withdraw_password.title') }}</h1>
                    <div class="help-block">
                        {{ $t('withdraw.missing_withdraw_pwd') }}
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">{{ $t('withdraw.label_withdraw_pwd') }}</div>
                <div class="card-body">
                    <loading-indicator v-if="!profile" />
                    <change-withdraw-password v-else :profile="profile" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ChangeWithdrawPassword from './Components/_ChangeWithdrawPassword.vue';
import { setProfile, clearProfile } from 'utilities/helper';

export default {
    components: { ChangeWithdrawPassword },

    data() {
        return {
            profile: null
        };
    },

    mounted() {
        this.getProfileAsync();
    },

    methods: {
        getProfileAsync: async function () {
            try {
                const resp = await this.$http.get('/api/v1/account/profile');
                const json = resp.data;
                if (json.errcode === 0) {
                    setProfile(json.data);
                    this.profile = json.data;
                }
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    // Unauthorized
                    clearProfile();
                } else {
                    console.log(`# get profile error: ${err}`);
                }
            }
        }
    }
};
</script>