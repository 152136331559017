<template>
    <header>
        <div class="header-left">
            <h1 class="header-logo d-flex flex-row">
                <!-- <router-link v-if="$route.path != '/'" to="/">
                    <i class="fa fa-chevron-left d-md-none"></i>
                </router-link> -->
                <router-link to="/">
                    <img src="/image/sitelogo" :alt="sysconfig.name" />
                </router-link>
            </h1>
            <nav class="header-nav">
                <!-- <router-link to="/finance/deposit">一键买币</router-link> -->
                <router-link to="/quotes" :class="{ active: active_tab_lg === 'quotes' }">{{ $t('home.footer_quotes') }}</router-link>
                <router-link to="/user/balance" :class="{ active: active_tab_lg === 'assets' }">{{ $t(sysconfig.is_hkmy ? 'home.header_buy_crypto_my' : 'home.header_buy_crypto') }}</router-link>

                <router-link to="/futures" :class="{ active: active_tab_lg === 'futures' }">{{ $t('general.futures') }}</router-link>
                <router-link to="/exchange" :class="{ active: active_tab_lg === 'exchange' }">{{ $t('general.exchange') }}</router-link>
                <router-link v-if="sysconfig.bOptionEnabled" to="/boption" :class="{ active: active_tab_lg === 'boption' }">{{ $t('general.boption') }}</router-link>
                <!-- <div class="header-menu">
                    <div class="header-menu-text dropdown-toggle">{{ $t('general.trade') }}</div>
                    <div class="header-dropdowns">
                        <router-link to="/futures">{{ $t('general.futures') }}</router-link>
                        <router-link to="/exchange">{{ $t('general.exchange') }}</router-link>
                        <router-link to="/boption">{{ $t('general.boption') }}</router-link>
                    </div>
                </div> -->

                <!-- <router-link to="/user/balance">{{ $t('home.footer_assets') }}</router-link>
                <div class="header-menu">
                    <div class="header-menu-text dropdown-toggle">{{ $t('home.header_orders') }}</div>
                    <div class="header-dropdowns">
                        <router-link to="/exchange/orders">{{ $t('home.header_exchange_orders') }}</router-link>
                        <router-link to="/futures/openorders">{{ $t('home.header_futures_orders') }}</router-link>
                        <router-link to="/boption/orders">{{ $t('home.header_boption_orders') }}</router-link>
                        <router-link to="/startup/orders">{{ $t('startup.label_orders') }}</router-link>
                    </div>
                </div> -->

                <router-link to="/startup" :class="{ active: active_tab_lg === 'startup' }">{{ $t(sysconfig.is_hk ? 'general.startup_hk' : 'general.startup') }}</router-link>
                <router-link v-if="sysconfig.savingsEnabled" to="/saving" :class="{ active: active_tab_lg === 'saving' }">{{ $t('home.header_saving') }}</router-link>
                <!-- <router-link to="/user/referrals">{{ $t('referrals.title') }}</router-link> -->
                <router-link to="/college" :class="{ active: active_tab_lg === 'college' }">{{ $t('home.header_college') }}</router-link>
                <router-link to="/notices" :class="{ active: active_tab_lg === 'notices' }">{{ $t('notices.notice_title') }}</router-link>
            </nav>
        </div>
        <div class="header-right">
            <div class="header-menu me-3 customer-service">
                <div class="header-menu-text">
                    <a href="javascript:;" onclick="toggle_udesk()" class="toggle-cs">
                        <svg viewBox="0 0 256 256" class="svg-icon cs-icon">
                        <use xlink:href="/dist/svg/icons.svg#v4.4-cs" />
                        </svg>
                        <!-- <img src="@/assets/images/v4.1/cs.png" alt="Customer Service" /> -->
                        {{ $t('general.customer_service_long') }}
                    </a>
                </div>
            </div>

            <!-- Login & register -->
            <div v-if="!context.profile" class="header-nologin">
                <!-- <div v-if="true" class="header-nologin"> -->
                <router-link to="/user/login" class="header-login">{{ $t('general.login') }}</router-link>
                <a href="javascript:;" onclick="toggle_udesk()" class=" college">
                    <svg viewBox="0 0 256 256" class="svg-icon cs-icon">
                        <use xlink:href="/dist/svg/icons.svg#v4.4-cs" />
                    </svg>
                    {{ $t('helps.title') }}
                </a>
                <router-link to="/user/create" class="header-register">{{ $t('general.register') }}</router-link>
            </div>

            <!-- balance / orders -->
            <div v-else class="header-userinfo">
                <a href="javascript:;" onclick="toggle_udesk()" class=" college">
                    <svg viewBox="0 0 256 256" class="svg-icon cs-icon">
                        <use xlink:href="/dist/svg/icons.svg#v4.4-cs" />
                    </svg>
                    {{ $t('helps.title') }}
                </a>
                <div class="header-menu" :class="{ show: visible_menu === 'account' }" @click="toggleMenu('account')">
                    <div class="header-menu-text"><i class="fa fa-user-lock"></i></div>
                    <div data-type="1" class="header-dropdowns">
                        <div class="header-dropdowns-header">
                            {{ context.profile.name }}
                            <span>{{ context.profile.realName }}</span>
                        </div>
                        <router-link to="/user/balance">{{ $t('home.footer_assets') }}</router-link>
                        <router-link to="/user">{{ $t('home.header_account_security') }}</router-link>
                        <!-- <router-link to="/user/qr">@(SettingsSR.QR_Title)</router-link> -->
                        <router-link to="/user/signout">{{ $t('signout.title') }}</router-link>
                    </div>
                </div>
            </div>

            <!-- QR image and language selectors -->
            <div v-if="sysconfig.downloadQR || langs.length > 0" class="header-exts">
                <!-- QR download image -->
                <div v-if="sysconfig.downloadQR" class="header-menu header-download" :class="{ show: visible_menu === 'download' }" @click="toggleMenu('download')">
                    <div class="header-menu-text">{{ $t('home.header_download') }}</div>
                    <div class="header-dropdowns">
                        <div>{{ $t('home.header_download_desp') }}</div>
                        <div class="p-3">
                            <div class="img-thumbnail">
                                <div class="img-app-qr" :style="'background-image: url(' + sysconfig.downloadQR + ')'"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- language selector -->
                <div class="header-menu" :class="{ show: visible_menu === 'lang' }" @click="toggleMenu('lang')">
                    <div class="header-menu-text">{{ getLocaleName($i18n.locale) }}</div>
                    <div data-type="1" class="header-dropdowns">
                        <a v-for="lang in langs" :key="lang" class="toggle-lang" href="javascript:;" @click="changeLang(lang)">
                            <svg class="flag-icon" viewBox="0 0 256 256">
                                <use :xlink:href="'/dist/svg/icons.svg#' + lang" />
                            </svg>
                            {{ getLocaleName(lang) }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-sm">
            <div class="d-flex align-items-center">
                <div>
                    <a v-if="$route.path != '/'" class="d-block pe-3" @click="goBack" href="javascript:;">
                        <i class="fa fa-chevron-left d-block d-md-none fs-3"></i>
                    </a>
                </div>
                <h1 class="header-logo d-flex flex-row flex-fill">
                    <!-- <a v-if="$route.path != '/'" href="javascript:void(0);" @click="$router.go(-1)">
                        <i class="fa fa-chevron-left d-md-none"></i>
                    </a> -->
                    <router-link to="/">
                        <img src="/image/sitelogo" :alt="sysconfig.name" />
                    </router-link>
                </h1>
                <!-- <div class="flex-fill header-search">
                    <input type="text" placeholder="BTC, ETH..." class="form-control" v-model="search_term" @focus="show_search_results = true" />
                    <div class="header-dropdowns" v-if="show_search_results">
                        <symbol-search-drop-downs :filter="search_term" @close="show_search_results = false" />
                    </div>
                </div> -->
                <!-- <div>
                    <a href="javascript:;" onclick="toggle_udesk()" class="toggle-cs">
                        <img src="@/assets/images/v4.1/cs.png" alt="Customer Service" />
                        <svg viewBox="0 0 256 256" class="svg-icon cs-icon">
                            <use xlink:href="/dist/svg/icons.svg#v4.1-cs" />
                        </svg>
                        {{ $t('general.customer_service_long') }}
                    </a>
                </div> -->
                <div class="header-nav-toggle ps-3">
                    <a href="javascript:;" onclick="toggle_udesk()" class=" college">
                        <svg viewBox="0 0 256 256" class="svg-icon cs-icon">
                            <use xlink:href="/dist/svg/icons.svg#v4.4-cs" />
                        </svg>
                        {{ $t('helps.title') }}
                    </a>
                    <a href="javascript:;" class="side-nav-toggle" @click="toggleSideNav">
                        <svg viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-icon">
                            <line y1="1.32422" x2="18" y2="1.32422" stroke-width="2" />
                            <line y1="7.32422" x2="12" y2="7.32422" stroke-width="2" />
                            <line y1="13.3242" x2="16" y2="13.3242" stroke-width="2" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <!-- Only display the sidebar for logged-in users -->
        <main-side-nav :active_tab="active_tab" />
    </header>
</template>

<style scoped>
.header-sm .side-nav-toggle {
    display: inline-block;
    /* margin-right: 1rem; */
}

.header-sm a,
.header-sm a:hover,
.header-sm a:hover>svg.svg-icon {
    color: var(--bottom-nav-active);
}

.header-sm svg.svg-icon {
    height: 2rem;
    width: 2rem;
    /* stroke-width: 4 !important; */
    fill: #fff;
    stroke: #fff;
}

.header-sm .side-nav-toggle>svg.svg-icon {
    height: 1.2rem;
    width: 2.2rem;
}

.toggle-cs {
    color: #000;
    padding: 0 1rem;
    border-radius: 2rem;
    background-color: #fcd535;
    text-align: center;
    display: flex;
    align-items: center;
    height: 2.5rem;
}

.header-sm .toggle-cs {
    display: inline-block;
    line-height: 1.5;
    /* color: #fff; */
}

/* .toggle-cs>svg.svg-icon {
    height: 1.25rem;
    width: 1.25rem;
     stroke: none;
    fill: currentColor
} */
/* .toggle-cs img {
    height: 1.25rem;
    width: 1.1rem;
} */

.header-nav-toggle>a,
.header-nav-toggle i {
    line-height: 3.5rem;
    height: 3.5rem;
    display: block;
}

a.header-login {
    height: 32px;
    line-height: 32px;
    background: #ffb11a;
    color: #17181e;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    font-weight: 600;
    border-radius: 4px;
}

svg.svg-icon.cs-icon {
    height: 1.25rem;
    width: 1.25rem;
    fill: #fcd535;
}
/* svg.svg-icon.cs-icon {
    height: 1.25rem;
    width: 1.25rem;
    fill: #000;
} */
.toggle-lang{
  display: flex;
  align-items: center;
}
.flag-icon{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 4px;
    border-radius: 11px;
}
.customer-service{
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}
.header-sm .customer-service{
    bottom: 5rem;
    right: 0.5rem;
}

.header-sm .customer-service a{
    color: #000;
    display: inline-flex;
    line-height: 1;
}
a.college{
  height: 32px;
  line-height: 32px;
  background: #ffb11a;
  color: #17181e;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  font-weight: 600;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.9rem;
}
.iframe-service{
  z-index: 2048;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 572px;
  max-height: 100vh;
  width: 364px;
  max-width: 100%;
  box-shadow: 0px 4px 12px 0px rgb(8 23 26 / 20%);
  display: flex;
  flex-direction: column;
}
.service-header{
  line-height: 3;
  padding: 0 1rem;
  color: #fff;
  background: #5D7CB6;
  border-radius: 3px 3px 0 0;
  display: flex;
  justify-content: space-between;
  height: 42px;
  width: 100%;
}
.service-header .close{
  background: none;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.iframe-service iframe{
  height: calc(100% - 42px);
  background-color: #EBF1FB;
}
</style>

<script>
import { setProfile, clearProfile, getAppContext } from 'utilities/helper';
import MainSideNav from './MainSideNav.vue';
import { Offcanvas } from 'bootstrap';
// import SymbolSearchDropDowns from './SymbolSearchDropDowns.vue';
import '@/assets/svg/icon-user-alt.svg';
import '@/assets/svg/icon-cs.svg';
import '@/assets/svg/icon-phone.svg';
import '@/assets/svg/v4.1/v4.1-cs.svg';
import '@/assets/svg/v4.4-cs.svg';
import '@/assets/svg/flag/zh.svg';
import '@/assets/svg/flag/en.svg';
import '@/assets/svg/flag/es.svg';
import '@/assets/svg/flag/ko.svg';
import '@/assets/svg/flag/ms.svg';
import '@/assets/svg/flag/ja.svg';
import '@/assets/svg/flag/de.svg';
import '@/assets/svg/flag/fr.svg';
import '@/assets/svg/flag/id.svg';
import '@/assets/svg/flag/it.svg';
import '@/assets/svg/flag/vi.svg';
import '@/assets/svg/flag/tl.svg';
import '@/assets/svg/flag/pt.svg';
import '@/assets/svg/flag/tr.svg';
import '@/assets/svg/flag/hi.svg';
import '@/assets/svg/flag/ar.svg';
import '@/assets/svg/flag/zh-CHT.svg';

export default {
    components: { MainSideNav },
    props: ['active_tab', 'active_tab_lg'],

    data() {
        return {
            langs: [],
            context: null,
            visible_menu: null,

            // The search term
            show_search_results: false,
            search_term: ''
        };
    },

    created() {
        this.context = getAppContext();
        this.delayReadProfile();
        this.langs = Object.freeze(this.sysconfig.supportedLanguages);
    },

    methods: {
        toggleMenu: function (name) {
            this.visible_menu = name === this.visible_menu ? null : name;
        },

        delayReadProfile: function () {
            const self = this;
            function func() {
                if (!self.context.profile) {
                    // Read profile from server side.
                    self.getProfileAsync();
                }
            }

            setTimeout(func, 500);
        },

        getProfileAsync: async function () {
            const self = this;
            try {
                const resp = await self.$http.get(g_server_root + '/api/v1/account/profile');
                const json = resp.data;
                if (json.errcode === 0) {
                    setProfile(json.data);
                }
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    // Unauthorized
                    clearProfile();
                } else {
                    console.log(`# get profile error: ${err}`);
                }
            }
        },

        changeLang: function (lang) {
            $.changeLang(this, lang);
        },

        toggleSideNav: function () {
            const c = Offcanvas.getInstance('#offcanvasNavbar');
            if (c) {
                c.show();
            }
        },

        goBack: function () {
            this.$router.back(-1);
        }
    }
};
</script>