<template>
    <section class="page help-page pb-5">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1 class="page-title">{{ $t('notices.notice_title') }}</h1>
                    <hr />
                </div>
            </div>

            <!-- loader -->
            <loading-indicator v-if="!all_notices" />

            <div v-else class="row">
                <div class="col">
                    <!-- no public notices? -->
                    <div v-if="all_notices.length === 0" class="no-data-indicator">{{ $t('general.no_data') }}</div>

                    <template v-else>
                        <!-- a list of all notices -->
                        <ul class="notice-list">
                            <li v-for="entity in paged_notices" :key="entity.id">
                                <div class="d-flex">
                                    <router-link class="flex-fill text-wrap" :to="'/notice/' + entity.id">
                                        <div v-html="entity.title"></div>
                                    </router-link>
                                    <div class="text-nowrap ps-3">{{ new Date(entity.timeCreated).formatDate() }}</div>
                                </div>
                            </li>
                        </ul>

                        <div class="mt-5">
                            <vue-pager :total="all_notices.length" :page_size="page_size" :page_index="page_index" @page-changed="onPageChanged" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script type="text/javascript">
import VuePager from '../Components/VuePager.vue';

export default {
    components: { VuePager },

    data: function () {
        return {
            // An array of notices
            all_notices: null,

            // The current page index
            page_index: 0,

            // The hard-coded page size
            page_size: 10
        };
    },

    created() {
        this.readAllNotices();
    },

    watch: {
        '$i18n.locale': function (newVal, oldVal) {
            this.readAllNotices();
        }
    },

    computed: {
        paged_notices: function () {
            const input = this.all_notices;
            if (input && input.length > 0) {
                const minPageIndex = this.page_index * this.page_size;
                const maxPageIndex = (this.page_index + 1) * this.page_size;

                const output = input.filter((elm, index) => {
                    return index >= minPageIndex && index < maxPageIndex;
                });
                console.log(`#### output length = ${output.length}`);
                return output;
            }

            return [];
        }
    },

    methods: {
        readAllNotices: function () {
            const self = this;
            self.all_notices = null;

            $.callGetApi(self, '/api/v1/notices').then((json) => {
                if (json.errcode === 0 && json.data) {
                    self.all_notices = Object.freeze(json.data);
                }
            });
        },

        onPageChanged: function (pageIndex) {
            this.page_index = pageIndex;
        }
    }
};
</script>