<template>
    <form @submit="upload_photos" action="javascript:void(0)" method="post" enctype="multipart/form-data" class="form-padding">
        <div class="form-group row">
            <div class="col">
                <div class="help-block">{{ $t('idverification.upload_desp') }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col">
                        <label class="form-label">{{ $t('idverification.label_realname') }}</label>
                        <input type="text" name="RealName" maxlength="64" class="form-control" autocomplete="off" data-val="true" data-val-required="*" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col">
                        <label class="form-label">{{ $t(sysconfig.is_hkmy ? 'idverification.label_id_number_my' : 'idverification.label_id_number') }}</label>
                        <input type="text" name="IDNumber" maxlength="18" class="form-control" autocomplete="off" data-val="true" data-val-required="*" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <photo-uploader-component name="IDCard_Front" :title="$t(sysconfig.is_hkmy ? 'idverification.upload_front_my' : 'idverification.upload_front')" />
            </div>
            <div class="col-md-6">
                <photo-uploader-component name="IDCard_Back" :title="$t(sysconfig.is_hkmy ? 'idverification.upload_back_my' : 'idverification.upload_back')" />
            </div>
            <div class="col-md-6">
                <!-- Is the holding picture requried or not? -->
                <photo-uploader-component v-if="profile.requiredIDLevel === 4" name="HoldingPhoto" :title="$t(sysconfig.is_hkmy ? 'idverification.upload_hold_my' : 'idverification.upload_hold')" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <submit-button ref="submitButton" :text="$t('idverification.label_submit')" />
            </div>
        </div>
    </form>
</template>

<script>
import PhotoUploaderComponent from './UploadID_PhotoUploader.vue';
import compressed from '../../../assets/js/Compressed'

export default {
    components: { PhotoUploaderComponent },

    props: ['profile'],

    created() {
        const profile = this.profile;
        if (!profile || typeof profile.requiredIDLevel !== 'number') throw new Error('A valid profile must be specified.');
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        upload_photos: function (e) {
            const self = this;
            const frm = e.target;
            // const testEnabled = this.profile.testEnabled === true;

            if ($(frm).valid()) {
                // all files are required.
                let valid = true;
                $(':file').each(function () {
                    if (!this.value || !this.value.length) valid = false;
                });

                if (!valid) {
                    $.top_error(self.$t('idverification.incomplete_photos'));
                } else {
                    // const debug_logs = [];
                    const _doPostAsync = async function () {
                        let frmData = new FormData(frm);

                        // if (testEnabled) {
                        //     debug_logs.push('Start compressing images.');
                        // }

                        // Compress all photos.
                        const photo_ids = ['IDCard_Front', 'IDCard_Back', 'HoldingPhoto'];
                        for (let i = 0; i < photo_ids.length; i++) {
                            const photo_key = photo_ids[i];
                            const oldPhoto = frmData.get(photo_key);
                            if (oldPhoto) {
                                try {
                                    // No need to compress the photo if its size is smaller than 200k
                                    let currentSize = parseInt(oldPhoto['size']);
                                    if (currentSize > 200 * 1024) {
                                        const updated = await compressed.compressImg(oldPhoto);

                                        // if (testEnabled)
                                        //     debug_logs.push(`${photo_key}: compressed from ${(currentSize / 1024).toFixed(2)} KB to ${(updated.size / 1024).toFixed(2)} KB`);
                                        frmData.set(photo_key, updated);
                                    }
                                    // else if (testEnabled) {
                                    //     debug_logs.push(`${photo_key}: skip compression, size=${(currentSize / 1024).toFixed(2)} KB`);
                                    // }
                                } catch (err) {
                                    // The error could be ignored.
                                    console.error(`Failed to compress ${photo_key}: ${err}`);

                                    // if (testEnabled)
                                    //     debug_logs.push(`${photo_key}: compression error: ${err}`);
                                }
                            }
                        }

                        // Upload images with retry.
                        let numAttempts = 0;
                        console.log(frmData,'######');
                        function uploadFunc() {
                            // if (testEnabled) {
                            //     debug_logs.push(`Uploading for #${numAttempts + 1} time`);
                            //     $.top_alert(debug_logs.join('\n'));
                            // }

                            $.ajax({
                                url: g_server_root + '/api/v1/identityverification',
                                type: 'POST',
                                data: frmData,
                                processData: false,
                                contentType: false,
                                success: function (json) {
                                    // if (testEnabled) {
                                    //     debug_logs.push(`Server response: ${JSON.stringify(json)}`);
                                    //     $.top_alert(debug_logs.join('\n'));
                                    // }

                                    if (json && json.errcode === 0) {
                                        $.top_alert(self.$t('idverification.uploaded'));
                                        setTimeout(() => {
                                            self.$router.push('/user/bankaccounts');
                                        }, 2000)
                                        self.$emit('submitted');
                                    } else {
                                        $.top_error(json.errmsg);
                                        self.$refs.submitButton.reset();
                                    }
                                },
                                error: function (err) {

                                    // Retry for up to 3 times
                                    if (++numAttempts < 3) {
                                        uploadFunc();
                                    } else {

                                        let err_msg;
                                        if (err && typeof err.responseJSON !== 'undefined') err_msg = err.responseJSON.errmsg;
                                        else {
                                            if (typeof err.status === 'number')
                                                err_msg = `Error ${err.status}: ` + self.$t('idverification.upload_error');
                                            else
                                                err_msg = self.$t('idverification.upload_error');
                                        }

                                        $.top_error(err_msg);
                                        // if (testEnabled) {
                                        //     debug_logs.push(err_msg);
                                        //     $.top_error(debug_logs.join('\n'));
                                        // } else {
                                        //     $.top_error(err_msg);
                                        // }
                                        self.$refs.submitButton.reset();
                                    }
                                }
                            });
                        }

                        uploadFunc();
                        // if (testEnabled) {
                        //     $.top_alert(debug_logs.join('\n'));
                        //     setTimeout(uploadFunc, 5000);
                        // } else {
                        //     uploadFunc();
                        // }
                    };

                    self.$refs.submitButton.submit();
                    setTimeout(function () {
                        _doPostAsync().then(() => {
                            // if (testEnabled) {
                            //     debug_logs.push('## Completed.');
                            //     $.top_alert(debug_logs.join('\n'));
                            // }
                        }).catch(err => {
                            // if (testEnabled) {
                            //     debug_logs.push(`## Upload failed: ${err}`);
                            //     $.top_error(debug_logs.join('\n'));
                            // }
                            console.error(`Error: ${err}`);
                            self.$refs.submitButton.reset();
                        });
                    }, 200);
                }
            }
        }
    }
};
</script>