<template>
    <div class="login container">
        <div class="login-inner" id="register-page">
            <form :class="{ 'd-none': !showInputs }" class="login-form" method="post" action="javascript:void(0);" @submit="createAccount">
                <h1>{{ $t('register.title') }}</h1>
                <!--
                Does both phone number and email are supported?
                -->
                <div class="page-top-nav mb-3" v-if="config.phoneSupported && config.emailSupported">
                    <li :class="{ active: !emailVCode }">
                        <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
                    </li>
                    <li :class="{ active: emailVCode }">
                        <a @click="emailVCode = true" href="javascript:;">{{ $t('register.label_email') }}</a>
                    </li>
                </div>

                <div class="form-group">
                    <template v-if="emailVCode">
                        <!-- Email account -->
                        <label v-if="!config.phoneSupported" class="form-label" for="Name">{{ $t('register.label_email') }}</label>

                        <!-- Required by the interface -->
                        <!-- <input type="hidden" name="RegionCode" value="+1" /> -->
                        <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
                    </template>
                    <template v-else>
                        <!-- Phone account -->
                        <label v-if="!config.emailSupported" class="form-label" for="Name">{{ $t('register.label_phone') }}</label>

                        <div class="d-flex">
                            <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                            <div class="flex-fill">
                                <input v-model="account" name="Name" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" :placeholder="$t('register.label_phone')" />
                            </div>
                        </div>
                    </template>
                </div>
                <div class="form-group">
                    <label class="form-label" for="Password">{{ $t('register.label_password') }}</label>
                    <div class="pwd-box">
                        <input name="Password" v-model="password" type="password" autocomplete="new-password"
                            class="form-control" data-val="true" data-val-required="*" data-val-maxlength="*"
                            data-val-maxlength-max="32" data-val-minlength="*" data-val-minlength-min="2"
                            :placeholder="$t('register.label_password')" :type="showPassword ? 'text' : 'password'" />
                        <svg viewBox="0 0 256 256" class="svg-icon showPwd-icon" @click="showPassword = !showPassword"
                            :class="showPassword ? 'active' : ''">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-displayPassword" />
                        </svg>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="ConfirmPassword">{{ $t('register.label_confirm_password') }}</label>
                    <div class="pwd-box">
                        <input name="ConfirmPassword" v-model="confirmPassword" type="password"
                            autocomplete="new-password" class="form-control" data-val="true" data-val-required="*"
                            data-val-equalto="*" data-val-equalto-other="Password" data-val-maxlength="*"
                            data-val-maxlength-max="32" data-val-minlength="*" data-val-minlength-min="6"
                            :placeholder="$t('register.label_confirm_password')" :type="PasswordConfirm ? 'text' : 'password'" />
                        <svg viewBox="0 0 256 256" class="svg-icon showPwd-icon" @click="PasswordConfirm = !PasswordConfirm"
                            :class="PasswordConfirm ? 'active' : ''">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-displayPassword" />
                        </svg>
                    </div>
                </div>
                <div class="form-group" v-if="emailVCode">
                    <label class="form-label" for="Phone">{{ $t('register.bind_phone') }}</label>
                    <div class="d-flex">
                        <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                        <div class="flex-fill">
                            <input v-model="Phone" name="Phone" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" :placeholder="$t('register.label_phone')" />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="ReferralCode">{{ $t('register.label_referral_code') }}</label>
                    <div class="code">
                        <input type="text" name="ReferralCode" v-model="refCode" autocomplete="off" class="form-control" data-val="true" data-val-required="*" :placeholder="$t('register.label_referral_code')" />
                    </div>
                </div>

                <!-- Do we need SMS verification? or just use a simple image verification code? -->
                <sms-verification v-if="config.signupVCodeRequired" :emailVCode="emailVCode" :regionCode="regionCode" :account="account" action="signup" />
                <template v-else>
                    <div class="form-group">{{ $t('register.label_verification_desp') }}: {{ regionCode }} {{ account }}</div>
                    <!-- <vcode-image /> -->

                    <!-- Use a dummy sms code to make the API happy. -->
                    <input type="hidden" name="Code" value="000000" />
                </template>

                <!-- <button class="btn btn-submit" type="submit">{{ $t('register.label_next_step') }}</button> -->
                <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
                    {{ $t('register.label_register') }}
                </button>
                <div class="text-center form-group">
                    <router-link to="/user/login">{{ $t('register.label_login') }}</router-link>
                </div>
            </form>
        </div>
    </div>
</template>
<style scoped>
.pwd-box {
    position: relative;
}

.pwd-box .showPwd-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
}

.showPwd-icon.active {
    fill: var(--primary-base);
    stroke: var(--primary-base);
}
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import '@/assets/svg/v4.2/v4.2-displayPassword.svg';
export default {
    components: { RegionSelector },
    props: ['config', 'regions', 'referral'],

    data() {
        return {
            regionCode: null,
            account: null,
            Phone:null,
            password: null,
            confirmPassword: null,
            refCode: null,
            showInputs: true,

            // Submit status
            submitting: false,
            completed: false,

            // which types of accounts are supported?
            emailVCode: false,
            showPassword: false,
            PasswordConfirm:false
        };
    },

    created() {
        const config = this.config;
        if (!config || config.signupEnabled !== true) throw new Error('signupEnabled must be true when creating new accounts.');

        const regions = this.regions;
        if (!Array.isArray(regions)) throw new Error('An array of supported regions are required.');

        this.refCode = this.referral;
    },

    watch: {
        referral: function () {
            this.refCode = this.referral;
        },

        emailVCode: function () {
            Vue.nextTick(() => {
                $.resetValidators();
            });
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        // goNextPage: function (e) {
        //     const frm = $(e.target);
        //     if (frm.valid()) {
        //         this.showInputs = false;
        //     }
        //     return false;
        // },

        // goPrevPage: function () {
        //     this.showInputs = true;
        // },

        createAccount: function (e) {
            const frm = $(e.target);
            if (!frm.valid()) return false;

            // post the server
            const data = frm.serialize();
            const self = this;
            self.submitting = true;

            $.callPostApi(self, '/api/v1/account/create', data)
                .then((json) => {
                    if (json && json.errcode === 0) {
                        try {
                            // Save the current authenticated user.
                            setProfile(json.data);

                            // redirect to home page
                            self.completed = true;

                            setTimeout(function () {
                                if (self.config.requiresWithdrawPassword === true) {
                                    self.$router.push('/user/changewithdrawpwd?skip=true');
                                } else {
                                    self.$router.push('/user/verifyid');
                                }
                            }, 500);
                        } catch (err) {
                            console.error(err);
                        }
                    } else {
                        $.top_error(json.errmsg);
                    }
                })
                .catch(function (err) {
                    $.top_error(self.$t('general.operation_error'));
                })
                .then(() => {
                    self.submitting = false;
                });
        },

        onRegionChanged: function (val) {
            console.log(`## Region changed to ${val}`);
            this.regionCode = val;
        }
    }
};
</script>